import logo from './logo.svg';
import './App.css';
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {AuthProvider} from "./contexts/AuthContext";
import {MessagesProvider} from "./contexts/MessagesContext";
import React from "react";

function App() {
  return (
      <AuthProvider>
          <MessagesProvider>
          <RouterProvider router={router} />
          </MessagesProvider>
      </AuthProvider>
  );
}

export default App;

/*<AuthProvider>
    <RouterProvider router={router} />
</AuthProvider>*/
